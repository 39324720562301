<template>
  <div class="check-name-show">
    <div class="col-sm-12">
      <div class="card mb-3">
        <div class="card-header position-relative">
          <h3 class="mb-0">Check response</h3>
        </div>
        <div class="card-body min-vh">

          <div 
            class="match-content text-center"
            v-if="matchInfo.code === 'CLOSE_MATCH'"
            >
            <p class="match-icon match-warning"><i class="el-icon-warning"></i></p>
            <p class="match-title">ACCOUNT NAME IS A CLOSE MATCH</p>
            <p class="match-detail">
              The account name you gave us isn't the same as
              the name held on the account.
            </p>
            <p class="match-title2">Please double check the detail.</p>
            <p class="match-title3">You entered</p>
            <p class="match-name">{{ Name }}</p>
            <p class="match-title3">Their bank told us:</p>
            <div>
              <p>Account name</p>
              <p class="match-name">{{ matchInfo.name }}</p>
            </div>
            <div class="text-center mt-6 mb-2">
              <el-button class="mb-2" type="success" @click="useMatchName">Use match name</el-button>
              <el-button class="mb-2" type="primary" plain @click="goBackAndEdit">Go back and edit</el-button>
              <el-button class="mb-2" type="primary" @click="continueEntered">Continue with what I entered</el-button>
            </div>
          </div>

          <div 
            class="match-content text-center"
            v-if="matchInfo.code === 'NOT_MATCHED'"
            >
            <p class="match-icon match-error"><i class="el-icon-error"></i></p>
            <p class="match-title match-error">ACCOUNT NAME DOES NOT MATCH</p>
            <p class="match-detail">
              The account name you gave us isn't the same as the name held on the
              account.
            </p>
            <p class="match-title2">Please double check the detail.</p>
            <p class="match-title3">You entered</p>
            <p class="match-name">{{ Name }}</p>
            <div class="text-center mt-6 mb-2">
              <el-button class="mb-2" type="primary" plain @click="goBackAndEdit">Go back and edit</el-button>
              <el-button class="mb-2" type="primary" @click="continueEntered">Continue with what I entered</el-button>
            </div>
          </div>

          <div 
            class="match-content text-center"
            v-if="matchInfo.code === 'BUSINESS_ACCOUNT_NAME_MATCHED'"
            >
            <p class="match-icon match-warning"><i class="el-icon-warning"></i></p>
            <p class="match-title">DID YOU MEAN TO PAY A BUSINESS ACCOUNT?</p>
            <p class="match-detail">
              The account name matches but the account type
              is incorrect, it's a <b>business</b> account and not a
              <b>personal</b> account.
            </p>
            <p class="match-title2">Please double check the detail.</p>
            <p class="match-title3">You entered</p>
            <p class="match-name">{{ Name }}</p>
            <!-- <span class="text-xs">{{ matchInfo.code }}</span> -->
            <div class="text-center mt-6 mb-2">
              <el-button class="mb-2" type="primary" plain @click="goBackAndEdit">Go back and edit</el-button>
              <el-button class="mb-2" type="primary" @click="continueEntered">Continue with what I entered</el-button>
            </div>
          </div>

          <div 
            class="match-content text-center"
            v-if="matchInfo.code === 'PERSONAL_ACCOUNT_NAME_MATCHED'"
            >
            <p class="match-icon match-warning"><i class="el-icon-warning"></i></p>
            <p class="match-title">DID YOU MEAN TO PAY A PERSONAL ACCOUNT?</p>
            <p class="match-detail">
              The account name matches but the account type
              is incorrect, it's a <b>personal</b> account and not a
               <b>business</b> account.
            </p>
            <p class="match-title2">Please double check the detail.</p>
            <p class="match-title3">You entered</p>
            <p class="match-name">{{ Name }}</p>
            <span class="text-xs">{{ matchInfo.code }}</span>
            <div class="text-center mt-6 mb-2">
              <el-button class="mb-2" type="primary" plain @click="goBackAndEdit">Go back and edit</el-button>
              <el-button class="mb-2" type="primary" @click="continueEntered">Continue with what I entered</el-button>
            </div>
          </div>

          <div 
            class="match-content text-center"
            v-if="matchInfo.code === 'BUSINESS_ACCOUNT_CLOSE_MATCH'"
            >
            <p class="match-icon match-warning"><i class="el-icon-warning"></i></p>
            <p class="match-title">ACCOUNT NAME IS A CLOSE MATCH WITH A BUSINESS ACCOUNT</p>
            <p class="match-detail">
              The account name you gave us isn't the same as
              the name held on the account, it's a <b>business</b>
              account and not a <b>personal</b> account.
            </p>
            <p class="match-title2">Please double check the detail.</p>
            <p class="match-title3">You entered</p>
            <p class="match-name">{{ Name }}</p>
            <span class="text-xs">BUSINESS ACCOUNT</span>
            <p class="match-title3">Their bank told us:</p>
            <div>
              <p>Account name</p>
              <p class="match-name">{{ matchInfo.name }}</p>
              <span class="text-xs">PERSONAL ACCOUNT</span>
            </div>
            <div class="text-center mt-6 mb-2">
              <el-button class="mb-2" type="success" @click="useMatchName">Use match name</el-button>
              <el-button class="mb-2" type="primary" plain @click="goBackAndEdit">Go back and edit</el-button>
              <el-button class="mb-2" type="primary" @click="continueEntered">Continue with what I entered</el-button>
            </div>
          </div>

          <div 
            class="match-content text-center"
            v-if="matchInfo.code === 'PERSONAL_ACCOUNT_CLOSE_MATCH'"
            >
            <p class="match-icon match-warning"><i class="el-icon-warning"></i></p>
            <p class="match-title">ACCOUNT NAME IS A CLOSE MATCH WITH A PERSONAL ACCOUNT</p>
            <p class="match-detail">
              The account name you gave us isn't the same as
              the name held on the account, it's a <b>personal</b> 
              account and not a <b>business</b> account.
            </p>
            <p class="match-title2">Please double check the detail.</p>
            <p class="match-title3">You entered</p>
            <p class="match-name mb--1">{{ Name }}</p>
            <span class="text-xs">PERSONAL ACCOUNT</span>
            <p class="match-title3 mt-4">Their bank told us:</p>
            <div>
              <p>Account name</p>
              <p class="match-name mb--1">{{ matchInfo.name }}</p>
              <span class="text-xs">BUSINESS ACCOUNT</span>
            </div>
            <div class="text-center mt-6 mb-2">
              <el-button class="mb-2" type="success" @click="useMatchName">Use match name</el-button>
              <el-button class="mb-2" type="primary" plain @click="goBackAndEdit">Go back and edit</el-button>
              <el-button class="mb-2" type="primary" @click="continueEntered">Continue with what I entered</el-button>
            </div>
          </div>

          <div 
            class="match-content text-center"
            v-if="matchInfo.code === 'ACCOUNT_DOES_NOT_EXIST'"
            >
            <p class="match-icon match-error"><i class="el-icon-error"></i></p>
            <p class="match-title match-error">PAYEE ACCOUNT DOESN'T EXIST</p>
            <p class="match-detail">
              The account details you've entered don't exist.
            </p>
            <p class="match-title2">Please check the detail with the payee.</p>
            <p class="match-title3">You entered</p>
            <p class="match-name">{{ Name }}</p>
            <p class="match-name">{{ payeeInfo.accountnumber }}</p>
            <p class="match-name">{{ payeeInfo.sortcode }}</p>
            <div class="text-center mt-6 mb-2">
              <el-button class="mb-2" type="primary" @click="goBackAndEdit">Go back</el-button>
            </div>
          </div>

          <div 
            class="match-content text-center"
            v-if="matchInfo.code === 'ACCOUNT_SWITCHED'"
            >
            <p class="match-icon match-error"><i class="el-icon-error"></i></p>
            <p class="match-title match-error">ACCOUNT SWITCHED</p>
            <p class="match-detail">
              The account has been switched using the current
              Account Switching Service.
            </p>
            <p class="match-title2">Please check the detail with the payee.</p>
            <p class="match-title3">You entered</p>
            <p class="match-name">{{ Name }}</p>
            <p class="match-name">{{ payeeInfo.accountnumber }}</p>
            <p class="match-name">{{ payeeInfo.sortcode }}</p>
            <div class="text-center mt-6 mb-2">
              <el-button class="mb-2" type="primary" @click="goBackAndEdit">Go back</el-button>
            </div>
          </div>

          <div 
            class="match-content text-center"
            v-if="['ACCOUNT_NOT_SUPPORTED', 'NOT_ENROLLED', 'WRONG_PARTICIPANT'].includes(matchInfo.code)"
            >
            <p class="match-icon match-warning"><i class="el-icon-warning"></i></p>
            <p class="match-title">ACCOUNT NAME CHECK NOT SUPPORTED</p>
            <p class="match-detail">The account name can't be checked.</p>
            <p class="match-detail">
              Only continue if you're sure the request is
              trustworthy and you have the correct details.
              </p>
           
            <p class="match-title3">You entered</p>
            <p class="match-name">{{ Name }}</p>
            <p class="match-name">{{ payeeInfo.accountnumber }}</p>
            <p class="match-name">{{ payeeInfo.sortcode }}</p>
            <div class="text-center mt-6 mb-2">
              <el-button class="mb-2" type="primary" plain @click="goBackAndEdit">Go back and edit</el-button>
              <el-button class="mb-2" type="primary" @click="continueEntered">Continue with what I entered</el-button>
            </div>
          </div>

          <div 
            class="match-content text-center"
            v-if="matchInfo.code === 'NO_RESPONSE'"
            >
            <p class="match-icon match-warning"><i class="el-icon-warning"></i></p>
            <p class="match-title">UNABLE TO CHECK ACCOUNT NAME</p>
            <p class="match-detail">
              We can't be check the account name right now.
              Please try again later.</p>
            <p class="match-detail">
              Only continue if you're sure the request is
              trustworthy and you have the correct details
            </p>
           
            <p class="match-title3">You entered</p>
            <p class="match-name">{{ Name }}</p>
            <p class="match-name">{{ payeeInfo.accountnumber }}</p>
            <p class="match-name">{{ payeeInfo.sortcode }}</p>
            <div class="text-center mt-6 mb-2">
              <el-button class="mb-2" type="primary" plain @click="goBackAndEdit">Go back and edit</el-button>
              <el-button class="mb-2" type="primary" @click="continueEntered">Continue with what I entered</el-button>
            </div>
          </div>

        </div>
      </div>
    </div>


      
  </div>
</template>

<script>
import { isMobile } from "@/utils/helpers";
export default {
  props:{
    payeeInfo:{
      type: Object,
    },
    matchInfo:{
      type: Object,
    }
  },
  computed: {
    isMobile() {
      return isMobile();
    },
    Name(){
      return this.payeeInfo.name ||  (this.payeeInfo.fname + " " + this.payeeInfo.lname);
    }
  },
  data() {
    return {
    };
  },
  methods: {
   goBackAndEdit(){
     this.$emit('goback');
   },
   continueEntered(){
     this.$emit('continue');
   },
   useMatchName(){
     this.$emit('usematchname');
   },
  },
};
</script>

<style lang="scss">
.check-name-show{
  .el-dialog__body{
    padding: 16px 20px;
  }

  .match-content{
  .match-icon{
    font-size: 50px;
    line-height: 1;
  }

  .match-title{
    font-size: 20px;
    color: #409eff;
    font-weight: 600;
    word-break: normal;
    word-wrap: break-word;
  }

  .match-warning{
    color: #ffc107 !important;
  }

  .match-error{
    color: #f5222d !important;
  }

  .match-title2{
    font-weight: 600;
  }

  .match-title3{
    font-weight: 600;
    color: #409eff;
  }

  .match-name{
    font-weight: 600;
  }

  .match-detail{
    word-break: normal;
    word-wrap: break-word;
  }
}
}
</style>