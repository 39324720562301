<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card mb-3">
        <div class="card-header position-relative">
          <h3 class="mb-0">Add Payees</h3>
        </div>

        <div class="card-body min-vh" v-loading="loading">
          <el-form
            v-if="payeeForm"
            ref="payeeForm"
            :model="payeeForm"
            :rules="payeeFormRule"
            :label-position="labelPosition"
            size="small"
            label-width="150px"
          >
            <div class="row justify-content-md-center">
              <div class="col-xl-6 col-lg-8 col-md-12">
                <el-form-item label="Currency">
                  <el-input
                    readonly
                    :value="Currency"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="First name" prop="fname">
                  <el-input
                    v-model="payeeForm.fname"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Last name" prop="lname">
                  <el-input
                    v-model="payeeForm.lname"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <SelectBankTH @onChange="bankSelect" />

                <el-form-item label="Account Number" prop="accountnumber">
                  <el-input
                    v-model="payeeForm.accountnumber"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="BIC" prop="bic">
                  <el-input
                    v-model="payeeForm.bic"
                    readonly
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Branch" prop="bankaddress">
                  <el-input
                    v-model="payeeForm.bankaddress"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Province" prop="citystate">
                  <el-input
                    v-model="payeeForm.citystate"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Reference" prop="reference">
                  <el-input
                    v-model="payeeForm.reference"
                    v-input-filter
                    show-word-limit
                    maxlength="18"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-divider content-position="left"
                  >Notification to receiver</el-divider
                >
                <el-form-item label="Telephone" prop="phonenumber">
                  <vue-tel-input
                    v-model="phonenumber"
                    defaultCountry="th"
                    class="form-control-alternative"
                    @validate="validatePhoneNumber"
                  >
                    <template slot="arrow-icon">
                      <span class="vti__dropdown-arrow">▼</span>
                    </template>
                  </vue-tel-input>
                </el-form-item>

                <el-form-item label="Email Address" prop="email">
                  <el-input
                    v-model="payeeForm.email"
                    type="email"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <div class="card-footer">
          <div class="row justify-content-xl-center">
            <div class="col-xl-12 text-center">
              <el-button @click="backView()">Cancel</el-button>
              <el-button
                type="primary"
                @click="onSubmit()"
                v-loading="submiting"
                :disabled="submiting"
                >Submit</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <PinDialog ref="pincodedialog" @checked="pincodeChecked" />
  </div>
</template>

<script>
import PayeeApi from "@/api/payee";
import { isMobile } from "@/utils/helpers";
import PinDialog from "@/components/PinCode/PinDialog";
import SelectBankTH from "./select-bank-th/SelectBankTH";
export default {
  components: {
    PinDialog,
    SelectBankTH,
  },
  computed: {
    Currency() {
      return this.$route.params.currency.toUpperCase();
    },
    plusuid() {
      return this.$store.getters.plusuid;
    },
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
    isMobile() {
      return isMobile();
    },
  },
  data() {
    return {
      loading: false,
      submiting: false,
      isPincode: false,
      phonenumber: "",
      payeeForm: {
        currency: "THB",
        bankname: "",
        accountnumber: "",
        name: "",
        fname: "",
        lname: "",
        bankaddress: "",
        citystate: "",
        reference: "",
        phonecode: "",
        phonenumber: "",
        email: "",
        bic: "",
      },
      payeeFormRule: {
        email: [
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"],
          },
        ],
        bankname: [
          {
            required: true,
            message: "Please enter Bank Name",
            trigger: "blur",
          },
        ],
        accountnumber: [
          {
            required: true,
            message: "Please enter Account munber",
            trigger: "blur",
          },
        ],
        fname: [
          {
            required: true,
            message: "Please enter First Name",
            trigger: "blur",
          },
        ],
        lname: [
          {
            required: true,
            message: "Please enter Last Name",
            trigger: "blur",
          },
        ],
        bankaddress: [
          { required: true, message: "Please enter Branch", trigger: "blur" },
        ],
        citystate: [
          { required: true, message: "Please enter Province", trigger: "blur" },
        ],
        reference: [
          {
            required: true,
            message:
              "Please enter Reference i.e. invoice number must put 6-18 character",
            min: 6,
            max: 18,
            trigger: ["blur", "change"],
          },
        ],
        email: [
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"],
          },
        ],
        bic: [{required: true, message: "Please select Bank Name", trigger: "blur"}],
      },
    };
  },
  methods: {
    pincodeChecked(pincode) {
      this.isPincode = pincode;
      if (!pincode) {
        this.submiting = false;
      } else {
        this.onSubmit();
      }
    },
    bankSelect(bank) {
      //console.log(bank)
      this.payeeForm.bankname = bank.name;
      this.payeeForm.bankaddress = bank.swiftcode;
      this.payeeForm.bic = bank.swiftcode;
    },
    onSubmit() {
      this.$refs.payeeForm.validate((valid) => {
        if (valid) {
          this.submiting = true;
          let formData = JSON.parse(JSON.stringify(this.payeeForm));
          if(formData.phonenumber){
            formData.phonenumber = formData.phonenumber.replace(/\s/g, "");
          }else{
            formData.phonecode = ""
            formData.phonenumber = "";
          }
          formData.name = formData.fname + " " + formData.lname;

          delete formData.fname;
          delete formData.lname;

          if (!this.isPincode && this.isMobile) {
            this.$refs.pincodedialog.show("bene-add");
            return false;
          }
          PayeeApi.postAdd(
            this.$route.params.currency,
            this.$route.params.type,
            formData
          ).then(
            ({ result, data, message }) => {
              this.submiting = false;
              if (result) {
                if (data.scapending) {
                  this.socketSubscription();
                } else {
                  this.$swal(`Message`, `Add Payee is success`, "success").then(
                    () => {
                      this.backView(data.payeeuid);
                    }
                  );
                }
              } else {
                this.$swal("Message", message, "error");
              }
            },
            (err) => {
              this.submiting = false;
            }
          );
        }
      });
    },

    backView(data) {
      if (this.$route.query.from) {
        this.$router.push(
          `/payment/${this.$route.params.currency}?payee=${data}`
        );
      } else {
        this.$router.push(
          `/payee/view/${this.$route.params.currency}/${this.$route.params.type}`
        );
      }
    },
    validatePhoneNumber(data) {
      this.payeeForm.phonecode = "+" + data.countryCallingCode;
      this.payeeForm.phonenumber = data.nationalNumber;
 
    },
    socketSubscription() {
      this.$socket.connect();
      this.$swalTimer(
        "Add Payee Pending",
        "Please confirm add payee from your mobile device",
        "warning"
      );
      this.$swal.showLoading();
      this.sockets.subscribe("bene-add:" + this.plusuid, (response) => {
        this.$swal.close();
        this.$socket.disconnect();

        if (response.result) {
          this.$swal.fire({
            icon: "success",
            title: "Add Payee is success",
            showConfirmButton: false,
          });
          setTimeout(() => {
            this.$swal.close();
            this.backView(response.data.payeeuid);
          }, 2000);
        } else {
          this.$swal("Message", response.message, "error");
        }
      });
    },
  },
};
</script>

<style></style>
