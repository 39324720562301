<template>
  <div class="check-name-show">
    <div class="col-sm-12">
      <div class="card mb-3">
        <div class="card-header position-relative">
          <h3 class="mb-0">Check response</h3>
        </div>
        <div class="card-body min-vh">

          <div 
            class="match-content text-center"
            >
            <p class="match-icon match-error" v-if="matchInfo.code === 'NOT_MATCHED'">
              <i class="el-icon-error"></i>
            </p>
            <p class="match-icon match-warning" v-else>
              <i class="el-icon-warning"></i>
            </p>
            <p class="match-detail">
              Making this payment may lead to money being
              sent to the wrong account which may not be
              recoverable.
            </p>
           
            <p class="match-title3">You entered</p>
            <p class="match-name">{{ Name }}</p>
            <div class="text-center mt-6 mb-2">
              <el-button class="mb-2" type="primary" @click="goBackAndEdit">Go back and edit</el-button>
              <el-button class="mb-2" type="primary" plain @click="confirmContinue">Continue with '{{ Name }}'</el-button>
            </div>
          </div>

        </div>
      </div>
    </div>


      
  </div>
</template>

<script>
import { isMobile } from "@/utils/helpers";
export default {
  props:{
    payeeInfo:{
      type: Object,
    },
    matchInfo:{
      type: Object,
    }
  },
  computed: {
    isMobile() {
      return isMobile();
    },
    Name(){
      return this.payeeInfo.name ||  (this.payeeInfo.fname + " " + this.payeeInfo.lname);
    }
  },
  data() {
    return {
    };
  },
  methods: {
   goBackAndEdit(){
     this.$emit('goback');
   },
   confirmContinue(){
     this.$emit('confirmmatch');
   }
  },
};
</script>

<style lang="scss">
.check-name-show{
  .el-dialog__body{
    padding: 16px 20px;
  }

  .match-content{
  .match-icon{
    font-size: 50px;
    line-height: 1;
  }

  .match-title{
    font-size: 20px;
    color: #409eff;
    font-weight: 600;
    word-break: normal;
    word-wrap: break-word;
  }

  .match-warning{
    color: #ffc107 !important;
  }

  .match-error{
    color: #f5222d !important;
  }

  .match-title2{
    font-weight: 600;
  }

  .match-title3{
    font-weight: 600;
    color: #409eff;
  }

  .match-name{
    font-weight: 600;
  }

  .match-detail{
    word-break: normal;
    word-wrap: break-word;
  }
}
}
</style>