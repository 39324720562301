<template>
  <div>
    <div class="container-fluid mt--6">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row justify-content-md-center">
            <div class="col-sm-12 text-center">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/payee' }">Manage Payee</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: `/payee/view/${$route.params.currency}/${$route.params.type}` }">{{ $titlePayeeType($route.params.currency) }}</el-breadcrumb-item>
                <el-breadcrumb-item>Add payee account details for receive swift payment worldwide</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
        </div>
      </div>
      <PayeeAddGBPEUR v-if="[typeGBP, typeEUR].includes(Currency)"/>
      <PayeeAddTHB v-if="[typeTHB].includes(Currency)"/>
      <PayeeAddSWT v-if="[typeSWT].includes(Currency)"/>
    </div>
  </div>
</template>

<script>
import PayeeAddGBPEUR from './components/PayeeAddGBPEUR.vue';
import PayeeAddTHB from './components/PayeeAddTHB.vue';
import PayeeAddSWT from './components/PayeeAddSWT.vue';
export default {
  components:{
    PayeeAddGBPEUR,
    PayeeAddTHB,
    PayeeAddSWT
  },
  computed: {
    Currency() {
      return this.$route.params.currency;
    },
  },
  data(){
    return {
      payeeName: '',
      typeGBP : 'gbp',
      typeEUR : 'eur',
      typeTHB : 'thb',
      typeSWT : 'swt' 
    }
  },
  methods:{
    setPayeeName(payeeName){
      this.payeeName = payeeName
    }
  }
}
</script>

<style>

</style>