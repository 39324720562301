<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card mb-3" v-show="!checkstep">
        <div class="card-header position-relative">
          <h3 class="mb-0">Add Payees</h3>
        </div>

        <div class="card-body min-vh" v-loading="loading">
          <el-form
            v-if="payeeForm"
            ref="payeeForm"
            :model="payeeForm"
            :rules="payeeFormRule"
            :label-position="labelPosition"
            size="small"
            label-width="200px"
          >
            <div
              class="row justify-content-md-center"
              v-if="Currency === 'GBP'"
            >
              <div class="col-xl-8 col-lg-8 col-md-12">
                <div class="alert alert-secondary small-text" role="alert">
                  <h4 class="alert-heading">
                    <i class="el-icon-info"></i> New payee account name check
                  </h4>
                  <p>
                    For additional security and to help protect against fraud,
                    we'll check the details you provide against the payee's
                    account details, including the name on their account.
                    Although this does not prevent all types of fraud, this will
                    help give you reassurance that you are paying the correct
                    person.
                  </p>
                </div>
              </div>
            </div>

            <div class="row justify-content-md-center">
              <div class="col-xl-6 col-lg-8 col-md-12">
                <el-form-item label="Type" prop="type">
                  <el-select
                    v-model="payeeForm.type"
                    placeholder="please select type"
                    class="form-control-alternative"
                    @change="clearValidate()"
                  >
                    <el-option label="Person" value="person"></el-option>
                    <el-option label="Company" value="company"></el-option>
                  </el-select>
                  <el-button
                    v-if="Currency === 'GBP'"
                    @click="
                      $swal(
                        'Personal or Business?',
                        `Stating whether the new payee you're
                    creating is either a Personal or a
                    Business account means we can
                    check the name associated with the
                    account more accurately.`,
                        'question'
                      )
                    "
                    class="btn-icon-question"
                    type="text"
                    icon="el-icon-question"
                  ></el-button>
                </el-form-item>

                <el-form-item label="Currency">
                  <el-input
                    readonly
                    :value="Currency"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Business account name"
                  prop="name"
                  v-if="payeeForm.type === 'company'"
                >
                  <el-input
                    v-model="payeeForm.name"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                  <el-button
                    v-if="Currency === 'GBP'"
                    @click="
                      $swal(
                        'Business payee account name',
                        `Please provide the payee's business
                    name, as held by their bank. This
                    allows us to check these details with
                    the payee's bank before you proceed.`,
                        'question'
                      )
                    "
                    class="btn-icon-question"
                    type="text"
                    icon="el-icon-question"
                  ></el-button>
                </el-form-item>

                <el-form-item
                  label="First name"
                  prop="fname"
                  v-if="payeeForm.type === 'person'"
                >
                  <el-input
                    v-model="payeeForm.fname"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                  <el-button
                    v-if="Currency === 'GBP'"
                    @click="
                      $swal(
                        'Personal payee account names',
                        `Please provide the payee's first and
                    last name, as held by their bank, and
                    not a short name or nickname. This
                    allows us to check these details with
                    the payee's bank before you proceed.`,
                        'question'
                      )
                    "
                    class="btn-icon-question"
                    type="text"
                    icon="el-icon-question"
                  ></el-button>
                </el-form-item>

                <el-form-item
                  label="Last name"
                  prop="lname"
                  v-if="payeeForm.type === 'person'"
                >
                  <el-input
                    v-model="payeeForm.lname"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                  <el-button
                    v-if="Currency === 'GBP'"
                    @click="
                      $swal(
                        'Personal payee account names',
                        `Please provide the payee's first and
                    last name, as held by their bank, and
                    not a short name or nickname. This
                    allows us to check these details with
                    the payee's bank before you proceed.`,
                        'question'
                      )
                    "
                    class="btn-icon-question"
                    type="text"
                    icon="el-icon-question"
                  ></el-button>
                </el-form-item>

                <SelectBankGBP
                  v-if="Currency === 'GBP'"
                  @onChange="bankSelect"
                />

                <el-form-item
                  label="Bank name"
                  prop="bankname"
                  v-if="Currency === 'EUR'"
                >
                  <el-input
                    v-model="payeeForm.bankname"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Account Number"
                  prop="accountnumber"
                  v-if="Currency === 'GBP'"
                >
                  <el-input
                    v-model="payeeForm.accountnumber"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Sortcode"
                  prop="sortcode"
                  v-if="Currency === 'GBP'"
                >
                  <el-input
                    v-model="payeeForm.sortcode"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="IBAN"
                  prop="iban"
                  v-if="Currency === 'EUR'"
                >
                  <el-input
                    v-model="payeeForm.iban"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="BIC" prop="bic" v-if="Currency === 'EUR'">
                  <el-input
                    v-model="payeeForm.bic"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Reference" prop="reference">
                  <el-input
                    v-model="payeeForm.reference"
                    show-word-limit
                    maxlength="18"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-divider content-position="left"
                  >Notification to receiver</el-divider
                >
                <el-form-item label="Telephone" prop="phonenumber">
                  <vue-tel-input
                    v-model="phonenumber"
                    class="form-control-alternative"
                    @validate="validatePhoneNumber"
                    @country-changed="changePhoneCountry"
                  >
                    <template slot="arrow-icon">
                      <span class="vti__dropdown-arrow">▼</span>
                    </template>
                  </vue-tel-input>
                </el-form-item>

                <el-form-item label="Email Address" prop="email">
                  <el-input
                    v-model="payeeForm.email"
                    type="email"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <!-- <el-form-item label="Document"> -->
                <!-- <div class="alert alert-danger" role="alert">
                    Please upload documents shown how to relating with beneficent account i.e. invoices, business agreement etc
                  </div> -->

                <UploadDocument
                  v-if="beneficiaryControl"
                  v-on:onChange="onChangeFiles"
                />
                <!-- </el-form-item> -->
              </div>
            </div>

            <b>Trading address if it different from above address </b>
            <el-switch v-model="moreinfo"></el-switch>

            <div class="row justify-content-md-center" v-if="moreinfo">
              <div
                class="col-xl-6 col-lg-8 col-md-12"
                v-if="payeeForm.type === 'person'"
              >
                <el-divider>Personal info</el-divider>

                <el-form-item label="Telephone" prop="personal.telephone">
                  <!-- <el-input v-model="payeeForm.personal.telephone" type="tel" class="form-control-alternative"></el-input> -->
                  <vue-tel-input
                    v-model="payeeForm.personal.telephone"
                    class="form-control-alternative"
                    ref="inputphonenumber-person"
                  >
                    <template slot="arrow-icon">
                      <span class="vti__dropdown-arrow">▼</span>
                    </template>
                  </vue-tel-input>
                </el-form-item>

                <el-form-item label="Email Address" prop="personal.email">
                  <el-input
                    v-model="payeeForm.personal.email"
                    type="email"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Nationality" prop="personal.nationality">
                  <el-select
                    v-model="payeeForm.personal.nationality"
                    filterable
                    class="form-control-alternative"
                  >
                    <el-option
                      v-for="item in country.filter((c) => c.nationality)"
                      :key="item.id"
                      :label="item.nationality"
                      :value="item.nationality"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Resident Country"
                  prop="personal.residentCountry"
                >
                  <el-select
                    v-model="payeeForm.personal.residentCountry"
                    filterable
                    class="form-control-alternative"
                  >
                    <el-option
                      v-for="item in country"
                      :key="item.id"
                      :label="item.countryName"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Address Number"
                  prop="personal.addressnumber"
                >
                  <el-input
                    v-model="payeeForm.personal.addressnumber"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Street" prop="personal.street">
                  <el-input
                    v-model="payeeForm.personal.street"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="City" prop="personal.city">
                  <el-input
                    v-model="payeeForm.personal.city"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Zipcode" prop="personal.zipcode">
                  <el-input
                    v-model="payeeForm.personal.zipcode"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Region" prop="personal.region">
                  <el-input
                    v-model="payeeForm.personal.region"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Country" prop="personal.countrycode">
                  <el-select
                    v-model="payeeForm.personal.countrycode"
                    filterable
                    class="form-control-alternative"
                  >
                    <el-option
                      v-for="item in country"
                      :key="item.id"
                      :label="item.countryName"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Identify Type"
                  prop="personal.identifyType"
                >
                  <el-select
                    v-model="payeeForm.personal.identifyType"
                    class="form-control-alternative"
                  >
                    <el-option label="Passport" value="passport"></el-option>
                    <el-option
                      label="Driving Licence"
                      value="driving-licence"
                    ></el-option>
                    <el-option
                      label="Identity Card"
                      value="identity-card"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Identify Number"
                  prop="personal.identifyNumber"
                >
                  <el-input
                    v-model="payeeForm.personal.identifyNumber"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Identify Expire"
                  prop="personal.identifyExpire"
                >
                  <el-input
                    v-model="payeeForm.personal.identifyExpire"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="PEP" prop="personal.pep">
                  <el-switch v-model="payeeForm.personal.pep"></el-switch>
                </el-form-item>

                <el-form-item
                  label="PEP Type"
                  prop="personal.pepType"
                  v-if="payeeForm.personal.pep"
                >
                  <el-select
                    v-model="payeeForm.personal.pepType"
                    class="form-control-alternative"
                  >
                    <el-option label="Direct" value="direct"></el-option>
                    <el-option
                      label="Close associate"
                      value="close-associate"
                    ></el-option>
                    <el-option
                      label="Former pep"
                      value="former-pep"
                    ></el-option>
                    <el-option
                      label="Family member"
                      value="family-member"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="PEP Notes"
                  prop="personal.pepNotes"
                  v-if="payeeForm.personal.pep"
                >
                  <el-input
                    v-model="payeeForm.personal.pepNotes"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>
              </div>

              <div
                class="col-xl-5 col-lg-8 col-md-12"
                v-if="payeeForm.type === 'company'"
              >
                <el-divider>Company info</el-divider>

                <el-form-item label="Trading Name" prop="company.tradingName">
                  <el-input
                    v-model="payeeForm.company.tradingName"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Financial"
                  prop="company.regulateFinancial"
                >
                  <el-switch
                    v-model="payeeForm.company.regulateFinancial"
                  ></el-switch>
                </el-form-item>

                <el-form-item
                  label="Registration Number"
                  prop="company.registrationNumber"
                >
                  <el-input
                    v-model="payeeForm.company.registrationNumber"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Nature of business"
                  prop="company.natureofbusiness"
                >
                  <el-input
                    v-model="payeeForm.company.natureofbusiness"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Type Of Business"
                  prop="company.typeOfBusiness"
                >
                  <el-input
                    v-model="payeeForm.company.typeOfBusiness"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Web Site" prop="company.webSite">
                  <el-input
                    v-model="payeeForm.company.webSite"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Email Address" prop="company.email">
                  <el-input
                    v-model="payeeForm.company.email"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Telephone" prop="company.telephone">
                  <!-- <el-input v-model="payeeForm.company.telephone" v-input-filter class="form-control-alternative"></el-input> -->
                  <vue-tel-input
                    v-model="payeeForm.company.telephone"
                    class="form-control-alternative"
                  >
                    <template slot="arrow-icon">
                      <span class="vti__dropdown-arrow">▼</span>
                    </template>
                  </vue-tel-input>
                </el-form-item>

                <el-form-item
                  label="Address Number"
                  prop="company.tradingAddress.addressnumber"
                >
                  <el-input
                    v-model="payeeForm.company.tradingAddress.addressnumber"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Street"
                  prop="company.tradingAddress.street"
                >
                  <el-input
                    v-model="payeeForm.company.tradingAddress.street"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="City" prop="company.tradingAddress.city">
                  <el-input
                    v-model="payeeForm.company.tradingAddress.city"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Zipcode"
                  prop="company.tradingAddress.zipcode"
                >
                  <el-input
                    v-model="payeeForm.company.tradingAddress.zipcode"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Region"
                  prop="company.tradingAddress.region"
                >
                  <el-input
                    v-model="payeeForm.company.tradingAddress.region"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Country"
                  prop="company.tradingAddress.countrycode"
                >
                  <el-select
                    v-model="payeeForm.company.tradingAddress.countrycode"
                    filterable
                    class="form-control-alternative"
                  >
                    <el-option
                      v-for="item in country"
                      :key="item.id"
                      :label="item.countryName"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div
                class="col-xl-5 col-lg-8 col-md-12"
                v-if="payeeForm.type === 'company'"
              >
                <el-divider>Director info</el-divider>

                <el-form-item label="Director Name" prop="director.name">
                  <el-input
                    v-model="payeeForm.director.name"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Position" prop="director.position">
                  <el-input
                    v-model="payeeForm.director.position"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Telephone" prop="director.telephone">
                  <!-- <el-input v-model="payeeForm.director.telephone" v-input-filter class="form-control-alternative"></el-input> -->
                  <vue-tel-input
                    v-model="payeeForm.director.telephone"
                    class="form-control-alternative"
                    ref="inputphonenumber-director"
                  >
                    <template slot="arrow-icon">
                      <span class="vti__dropdown-arrow">▼</span>
                    </template>
                  </vue-tel-input>
                </el-form-item>

                <el-form-item label="Email Address" prop="director.email">
                  <el-input
                    v-model="payeeForm.director.email"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Nationality" prop="director.nationality">
                  <el-select
                    v-model="payeeForm.director.nationality"
                    filterable
                    class="form-control-alternative"
                  >
                    <el-option
                      v-for="item in country.filter((c) => c.nationality)"
                      :key="item.id"
                      :label="item.nationality"
                      :value="item.nationality"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Resident Country"
                  prop="director.residentCountry"
                >
                  <el-select
                    v-model="payeeForm.director.residentCountry"
                    filterable
                    class="form-control-alternative"
                  >
                    <el-option
                      v-for="item in country"
                      :key="item.id"
                      :label="item.countryName"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Identify Type"
                  prop="director.identifyType"
                >
                  <el-select
                    v-model="payeeForm.director.identifyType"
                    class="form-control-alternative"
                  >
                    <el-option label="Passport" value="passport"></el-option>
                    <el-option
                      label="Driving Licence"
                      value="driving-licence"
                    ></el-option>
                    <el-option
                      label="Identity Card"
                      value="identity-card"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Identify Number"
                  prop="director.identifyNumber"
                >
                  <el-input
                    v-model="payeeForm.director.identifyNumber"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Identify Expire"
                  prop="director.identifyExpire"
                >
                  <el-input
                    v-model="payeeForm.director.identifyExpire"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="PEP" prop="director.pep">
                  <el-switch v-model="payeeForm.director.pep"></el-switch>
                </el-form-item>

                <el-form-item
                  label="PEP Type"
                  prop="director.pepType"
                  v-if="payeeForm.director.pep"
                >
                  <el-select
                    v-model="payeeForm.director.pepType"
                    class="form-control-alternative"
                  >
                    <el-option label="Direct" value="direct"></el-option>
                    <el-option
                      label="Close associate"
                      value="close-associate"
                    ></el-option>
                    <el-option
                      label="Former pep"
                      value="former-pep"
                    ></el-option>
                    <el-option
                      label="Family member"
                      value="family-member"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="PEP Note"
                  prop="director.pepNotes"
                  v-if="payeeForm.director.pep"
                >
                  <el-input
                    v-model="payeeForm.director.pepNotes"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <div class="card-footer">
          <div class="row justify-content-xl-center">
            <div class="col-xl-12 text-center">
              <el-button @click="backView()">Cancel</el-button>
              <el-button
                type="primary"
                @click="onContinue()"
                v-loading="submiting"
                :disabled="submiting"
                v-if="Currency === 'GBP' && isFeatureCOP"
                >Continue</el-button
              >
              <el-button
                type="primary"
                @click="onSubmit()"
                v-loading="submiting"
                :disabled="submiting"
                v-else
                >Submit</el-button
              >
            </div>
          </div>
        </div>
      </div>

      <Response
        v-if="checkstep === 'response'"
        :payee-info="payeeForm"
        :match-info="matchInfo"
        v-on:goback="backToEdit()"
        v-on:continue="continueEntered()"
        v-on:usematchname="useMatchName()"
      />
      <Continue
        v-if="checkstep === 'continue'"
        :payee-info="payeeForm"
        :match-info="matchInfo"
        v-on:goback="backToEdit()"
        v-on:confirmmatch="confirmMatch()"
      />
      <Confirm
        v-if="checkstep === 'confirm'"
        :payee-info="payeeForm"
        :match-info="matchInfo"
        v-on:confirmed="confirmed()"
        v-on:cancelled="cancelled()"
      />
    </div>

    <PinDialog ref="pincodedialog" @checked="pincodeChecked" />
  </div>
</template>

<script>
import PayeeApi from "@/api/payee";
import PayeeModel from "./PayeeModel";
import UtilsApi from "@/api/utility";
import { isMobile } from "@/utils/helpers";
import PinDialog from "@/components/PinCode/PinDialog";
import Response from "./gbp-check/response";
import Continue from "./gbp-check/continue";
import Confirm from "./gbp-check/confirm";
import UploadDocument from "./UploadDocument";
import Auth from "@/api/auth";
import axios from "axios";
import SelectBankGBP from "./select-bank-gbp/SelectBankGBP";
export default {
  components: {
    PinDialog,
    Response,
    Continue,
    Confirm,
    UploadDocument,
    SelectBankGBP,
  },
  computed: {
    Currency() {
      return this.$route.params.currency.toUpperCase();
    },
    plusuid() {
      return this.$store.getters.plusuid;
    },
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
    isMobile() {
      return isMobile();
    },
    isFeatureCOP() {
      return this.$store.getters.feature.cop;
    },
  },
  data() {
    return {
      loading: false,
      submiting: false,
      moreinfo: false,
      isPincode: false,
      country: [],
      payeeForm: PayeeModel.form(),
      payeeFormRule: PayeeModel.rule,
      phonenumber: "",
      phonecodeCountry: "",
      checkstep: "",
      matchInfo: {},
      testList: {
        NOTMATCHED: "NOT_MATCHED",
        CLOSEMATCH: "CLOSE_MATCH",
        BUSINESSACCOUNTNAMEMATCHED: "BUSINESS_ACCOUNT_NAME_MATCHED",
        PERSONALACCOUNTNAMEMATCHED: "PERSONAL_ACCOUNT_NAME_MATCHED",
        BUSINESSACCOUNTCLOSEMATCH: "BUSINESS_ACCOUNT_CLOSE_MATCH",
        PERSONALACCOUNTCLOSEMATCH: "PERSONAL_ACCOUNT_CLOSE_MATCH",
        ACCOUNTDOESNOTEXIST: "ACCOUNT_DOES_NOT_EXIST",
        SECONDARYACCOUNTIDNOTFOUND: "SECONDARY_ACCOUNT_ID_NOT_FOUND",
        ACCOUNTNOTSUPPORTED: "ACCOUNT_NOT_SUPPORTED",
        ACCOUNTSWITCHED: "ACCOUNT_SWITCHED",
        WRONGPARTICIPANT: "WRONG_PARTICIPANT",
        NORESPONSE: "NO_RESPONSE",
        NOTENROLLED: "NOT_ENROLLED",
      },
      files: [],
      beneficiaryControl: false,
      copResult: {
        NOT_MATCHED: "mismatched",
        CLOSE_MATCH: "closedmatched",
        BUSINESS_ACCOUNT_NAME_MATCHED: "matched",
        PERSONAL_ACCOUNT_NAME_MATCHED: "matched",
        BUSINESS_ACCOUNT_CLOSE_MATCH: "closedmatched",
        PERSONAL_ACCOUNT_CLOSE_MATCH: "closedmatched",
        ACCOUNT_DOES_NOT_EXIST: "notfound",
        SECONDARY_ACCOUNT_ID_NOT_FOUND: "notfound",
        ACCOUNT_NOT_SUPPORTED: "notfound",
        ACCOUNT_SWITCHED: "notfound",
        WRONG_PARTICIPANT: "checkerror",
        NO_RESPONSE: "checkerror",
        NOT_ENROLLED: "checkerror",
        VERIFY_FAIL: "checkerror",
      },
    };
  },
  methods: {
    getCountry() {
      UtilsApi.getCountry().then(({ result, data }) => {
        if (result) {
          this.country = data;
        }
      });
    },
    bankSelect(bank) {
      //console.log(bank)
      this.payeeForm.bankname = bank.name;
    },
    clearValidate() {
      this.$refs.payeeForm.clearValidate();
    },
    clearMoreinfo() {
      if (!this.moreinfo) {
        const model = PayeeModel.form();
        this.payeeForm.company = model.company;
        this.payeeForm.director = model.director;
        this.payeeForm.personal = model.personal;
      }
    },
    pincodeChecked(pincode) {
      this.isPincode = pincode;
      if (!pincode) {
        this.submiting = false;
      } else {
        this.onSubmit();
      }
    },
    addPayee() {
      this.submiting = true;

      this.clearMoreinfo();
      let formData = JSON.parse(JSON.stringify(this.payeeForm));
      if (formData.phonenumber) {
        formData.phonenumber = formData.phonenumber.replace(/\s/g, "");
      } else {
        formData.phonecode = "";
        formData.phonenumber = "";
      }

      formData.company.telephone = formData.company.telephone.replace(
        /\s/g,
        ""
      );
      formData.director.telephone = formData.director.telephone.replace(
        /\s/g,
        ""
      );
      formData.personal.telephone = formData.personal.telephone.replace(
        /\s/g,
        ""
      );

      if (!this.isPincode && this.isMobile) {
        this.$refs.pincodedialog.show("bene-add");
        return false;
      }

      if (formData.type === "person") {
        formData.name = formData.fname + " " + formData.lname;
      }

      delete formData.fname;
      delete formData.lname;

      PayeeApi.postAdd(
        this.$route.params.currency,
        this.$route.params.type,
        formData
      ).then(({ result, data, message }) => {
        this.submiting = false;
        if (result) {
          if (data.scapending) {
            this.socketSubscription();
          } else {
            this.onSubmited(data);
          }
        } else {
          this.$swal("Message", message, "error");
        }
      });
    },
    onSubmit() {
      this.$refs.payeeForm.validate((valid) => {
        if (valid) {
          this.addPayee();
        }
      });
    },
    socketSubscription() {
      this.$socket.connect();
      this.$swalTimer(
        "Add Payee Pending",
        "Please confirm add payee from your mobile device",
        "warning"
      );
      this.$swal.showLoading();
      this.sockets.subscribe("bene-add:" + this.plusuid, (response) => {
        this.$swal.close();
        this.$socket.disconnect();
        if (response.result) {
          this.onSubmited(response.data);
        } else {
          this.$swal("Message", response.message, "error");
        }
      });
    },
    onSubmited(data) {
      if (this.beneficiaryControl == true) {
        this.uploadFiles(data);
      } else {
        this.$swal(`Message`, `Add Payee is success`, "success").then(() => {
          this.backView(data.payeeuid);
        });
      }
    },
    backView(data) {
      if (this.$route.query.from) {
        this.$router.push(
          `/payment/${this.$route.params.currency}?payee=${data}`
        );
      } else {
        this.$router.push(
          `/payee/view/${this.$route.params.currency}/${this.$route.params.type}`
        );
      }
    },
    onContinue() {
      this.$refs.payeeForm.validate((valid) => {
        if (valid) {
          let formData = JSON.parse(JSON.stringify(this.payeeForm));
          if (formData.type === "person") {
            formData.name = formData.fname + " " + formData.lname;
          }
          let name = formData.name
            ? formData.name.replace(/[^\a-\z\A-\Z0-9 ]/g, "")
            : "";
          let fname = formData.fname
            ? formData.fname.replace(/[^\a-\z\A-\Z0-9 ]/g, "")
            : "";
          let keyType = this.testList[fname] || this.testList[name];
          let formCheck = {
            name: keyType || name,
            type: this.payeeForm.type === "person" ? "PERSONAL" : "BUSINESS",
            accountnumber: this.payeeForm.accountnumber,
            sortcode: this.payeeForm.sortcode,
          };
          this.submiting = true;
          PayeeApi.postGbpCheck(formCheck).then(
            ({ result, data, message }) => {
              this.submiting = false;
              if (result) {
                this.matchInfo = data;
                this.payeeForm.verify = "verified";
                this.payeeForm.copResult = this.copResult[data.code];
                if (data.code === "MATCHED") {
                  this.checkstep = "confirm";
                } else if (data.code === "VERIFY_FAIL") {
                  this.payeeForm.verify = "unverified";
                  this.checkstep = "confirm";
                } else {
                  this.checkstep = "response";
                }
              } else {
                this.$swal("Message", message, "error");
              }
            },
            (err) => {
              this.submiting = false;
            }
          );
        }
      });
    },
    backToEdit() {
      this.checkstep = "";
      let formData = JSON.parse(JSON.stringify(this.payeeForm));
      this.payeeForm.name = formData.name
        ? formData.name.replace(/[^\a-\z\A-\Z0-9 ]/g, "")
        : "";
      this.payeeForm.fname = formData.fname;
      this.payeeForm.lname = formData.lname;
    },
    continueEntered() {
      this.checkstep = "continue";
    },
    useMatchName() {
      var regex = /(Mr|MR|Ms|Miss|Mrs|Dr|Sir)(\.?)\s/;
      var match = regex.exec(this.matchInfo.name);
      this.payeeForm.name =
        match !== null
          ? this.matchInfo.name.replace(match[0], "")
          : this.matchInfo.name;
      let splitName = this.payeeForm.name.split(" ");

      if (this.payeeForm.type == "person") {
        this.payeeForm.fname = splitName[0];
        this.payeeForm.lname = splitName[1];
      }

      this.checkstep = "confirm";
    },
    confirmMatch() {
      this.checkstep = "confirm";
    },
    confirmed() {
      this.addPayee();
      this.checkstep = "";
    },
    checkBeneControl() {
      PayeeApi.checkBeneControl().then(({ result, data }) => {
        if (result) {
          this.beneficiaryControl = data.beneficiaryControl;
        }
      });
    },
    onChangeFiles(files) {
      this.files = files;
      this.payeeForm.fileCount = this.files.length;
    },
    uploadFiles(data) {
      const files = this.files;

      if (files.length == 0) {
        this.$swal(`Message`, `Add Payee is success`, "success").then(() => {
          this.backView(data.payeeuid);
        });
        return false;
      }

      const formData = new FormData();
      formData.append("type", "create");
      Array.from(files).forEach((file) => {
        formData.append("beneficiary_files", file);
      });
      let url = `${process.env.VUE_APP_SERVER_BASE_API}/api/services/v1/payee/documents/${data.beneficiaryId}`;
      axios({
        method: "POST",
        url: url,
        data: formData,
        headers: {
          authorization: Auth.getToken(),
        },
      })
        .then((response) => {
          this.submiting = false;
          if (response.data.result) {
            this.$swal(`Message`, `Add Payee is success`, "success").then(
              () => {
                this.backView(data.payeeuid);
              }
            );
          } else {
            this.$swal("Message", message, "error");
          }
        })
        .catch((error) => {
          this.submiting = false;
        });
    },
    validatePhoneNumber(data) {
      this.payeeForm.phonecode = "+" + data.countryCallingCode;
      this.payeeForm.phonenumber = data.nationalNumber;
      this.phonecodeCountry = data.countryCode;
      if (this.moreinfo) {
        if (this.payeeForm.type == "person") {
          this.payeeForm.personal.telephone = data.number;
        } else {
          this.payeeForm.personal.director = data.number;
        }
      }
    },
    changePhoneCountry(data) {
      this.phonecodeCountry = data.iso2;
      if (this.moreinfo) {
        let getphonenumber =
          this.payeeForm.phonecode + this.payeeForm.phonenumber;
        let notempty = this.payeeForm.phonecode && this.payeeForm.phonenumber;
        if (this.payeeForm.type == "person") {
          this.$refs["inputphonenumber-person"] &&
            this.$refs["inputphonenumber-person"].choose(this.phonecodeCountry);
          if (notempty) this.payeeForm.personal.telephone = getphonenumber;
        } else {
          this.$refs["inputphonenumber-director"] &&
            this.$refs["inputphonenumber-director"].choose(
              this.phonecodeCountry
            );
          if (notempty) this.payeeForm.director.telephone = getphonenumber;
        }
      }
    },
    cancelled() {
      if (this.Currency === "GBP" && this.isFeatureCOP) {
        let formData = JSON.parse(JSON.stringify(this.payeeForm));

        if (formData.type === "person") {
          formData.name = formData.fname + " " + formData.lname;
        }

        let data = {
          name: formData.name,
          type: this.payeeForm.type,
          currency: "gbp",
          accountnumber: this.payeeForm.accountnumber,
          sortcode: this.payeeForm.sortcode,
          reference: this.payeeForm.reference,
          copResult: this.payeeForm.copResult,
        };

        PayeeApi.postGbpLog(data).then(({ result, message }) => {
          if (result) {
            this.backView();
          } else {
            this.$swal("Message", message, "error");
          }
        });
      } else {
        this.backToEdit();
      }
    },
  },

  mounted() {
    this.payeeForm.currency = this.Currency;
    this.payeeForm.type = "person";
    // this.payeeForm.name = "waw soft";
    // this.payeeForm.accountnumber = "12345678";
    // this.payeeForm.sortcode = "000000";
    // this.payeeForm.reference = "12345678";
    this.getCountry();
    this.checkBeneControl();
  },
};
</script>

<style lang="scss">
.small-text {
  .alert-heading {
    color: #525f7f !important;
    font-size: 0.8375rem;
  }
  p {
    color: #525f7f !important;
    font-size: 12px;
  }
}
</style>
